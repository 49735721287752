/* eslint-disable no-magic-numbers */
import { StaticQuery, graphql, PageProps } from 'gatsby'
import React from 'react'

import {
  Hero,
  ExpandedBenefit,
  Benefits,
  Split,
  Products,
  PrimaryBanner,
  Questions,
  Footer,
  VideoStory,
} from '@alteos/ui'
import { IDropdown, ILink, ILinkWithClickHandler } from '@alteos/ui/types/ts.constants'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Divider from '../sections/Divider'
import {
  getCtaParams,
  mapFeaturesContent,
  mapSeoElements,
  mapFaqSection,
} from '../utils/sectionMapsLegacy'
import socialLinks from '../utils/socialLinks'


interface IIndexPageData {
  // TODO: get this from git history, types/index
}

interface IIndexPage {
  data: IIndexPageData
}

const mapContent = (
  data: IIndexPageData,
  setPopupState: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const headerLinks = [
    {
      label: 'Produkte',
      to: '#product',
    },
    ...data.headerContent.content.map((item) => ({
      to: item.ctaTo,
      label: item.ctaLabel,
    })),
  ]
  const header = {
    items: headerLinks,
  } as {
    items: Array<ILink | ILinkWithClickHandler>
    desktopMenu: IDropdown[]
  }

  const hero = {
    title: data.heroContent.title.title,
    subtitle: data.heroContent.subTitle.subTitle,
    image: data.heroContent.image,
    ctaButtons: [
      {
        to: data.heroContent.cta.ctaTo,
        label: data.heroContent.cta.ctaLabel,
      },
    ],
  }

  const onboarding = mapFeaturesContent(data.mainContent[0])
  const bonusInformation = mapFeaturesContent(data.mainContent[1])
  const customerLoyalty = mapFeaturesContent(data.mainContent[2])
  const productsAvailable = mapFeaturesContent(data.mainContent[3])

  productsAvailable.items[0].url = '/garantie'
  productsAvailable.items[1].url = '/ebike'
  productsAvailable.items[2].url = '/hoerschutz'
  productsAvailable.items[3]!.url = '/dienstradanschluss'
  productsAvailable.items[0].disabled = true
  productsAvailable.items[1].disabled = true
  productsAvailable.items[2].disabled = true
  productsAvailable.items[3]!.disabled = true

  const partnerInstructions = mapFeaturesContent(data.mainContent[4], {
    withCtaButton: true,
  })

  const customerOnboarding = mapFeaturesContent(data.mainContent[5])
  const usp = mapFeaturesContent(data.mainContent[6])
  const provision = mapFeaturesContent(data.mainContent[7], {
    withCtaButton: true,
    withLogoCaption: true,
  })
  const benefits = mapFeaturesContent(data.mainContent[8], {
    withCtaButton: true,
  })
  const dashboard = mapFeaturesContent(data.mainContent[9], {
    withCtaButton: true,
  })
  const faq = mapFaqSection(data.faqContent)

  const subsidiary = mapFeaturesContent(data.mainContent[10], {
    withCtaLink: true,
  })

  const footer = {
    copyright: data.footerContent.copyright.copyright,
    items: data.footerContent.content.map((item) => getCtaParams(item, setPopupState)),
    socialLinks,
  }

  const seo = mapSeoElements(data)

  return {
    header,
    hero,
    onboarding,
    bonusInformation,
    customerLoyalty,
    productsAvailable,
    partnerInstructions,
    customerOnboarding,
    usp,
    provision,
    benefits,
    dashboard,
    faq,
    subsidiary,
    footer,
    seo,
  }
}

const Page = ({ data }: IIndexPage) => (
  <Layout>
    {({ setPopupState }) => {
      const content = mapContent(data, setPopupState)

      return (
        <>
          <Hero
            logoWithPartner={null}
            tagline="tagline"
            isLight={false}
            fullMobileLogo={false}
            dataTest="Hero"
            {...content.hero}
            headerContent={content.header}
            partnerName={null}
          />
          <Benefits
            dataTest="Benefits"
            type="centerer"
            {...content.onboarding}
            withBackground
            featureNumbered
          />
          <ExpandedBenefit dataTest="ExpandedBenefit" {...content.bonusInformation} />
          <ExpandedBenefit
            dataTest="ExpandedBenefit"
            {...content.customerLoyalty}
            withBackground
            isReversed
          />
          <Products dataTest="Products" {...content.productsAvailable} id="product" />
          <VideoStory
            title="Hinter den Kulissen von Alteos"
            videoPath="https://player.vimeo.com/video/560834975?transparent=0"
            withBackground
            dataTest="video-story"
            containerProps={{
              id: 'behind-the-scenes',
            }}
          />
          <Split dataTest="Split" {...content.partnerInstructions} />
          <Benefits
            dataTest="Benefits"
            type="inliner"
            {...content.customerOnboarding}
            twoColumns
            withBackground
          />
          <Divider />
          <Benefits dataTest="Benefitss" {...content.usp} noPaddingBottom />
          <PrimaryBanner
            noPaddingTop={false}
            noPaddingBottom={false}
            axaLogo={false}
            dataTest="PrimaryBanner"
            {...content.provision}
            smallPaddingBottom
          />
          <Benefits dataTest="Benefits" {...content.benefits} withBackground />
          <Benefits dataTest="Benefits" {...content.dashboard} />
          <Questions {...content.faq} withBackground />
          <Split dataTest="Split" {...content.subsidiary} />
          <Footer {...content.footer} />
          <Seo noIndex={false} {...content.seo} />
        </>
      )
    }}
  </Layout>
)

const PageWithQuery = (props: PageProps): JSX.Element => (
  <StaticQuery
    query={graphql`
      {
        contentfulLandingPageContent {
          headerContent {
            content {
              ctaLabel
              ctaTo
            }
          }
          heroContent {
            title {
              title
            }
            subTitle {
              subTitle
            }
            image {
              file {
                url
              }
            }
            cta {
              ctaLabel
              ctaTo
            }
          }
          mainContent {
            title {
              title
            }
            content {
              content
            }
            image {
              file {
                url
              }
            }
            components {
              ... on ContentfulIndividualSectionalContent {
                title {
                  title
                }
                content {
                  content
                }
                image {
                  file {
                    url
                  }
                }
              }
            }
            cta {
              ctaLabel
              ctaTo
            }
          }
          faqContent {
            title
            content {
              answer {
                answer
              }
              question {
                question
              }
            }
            cta {
              ctaLabel
              ctaTo
            }
          }
          footerContent {
            copyright {
              copyright
            }
            content {
              ctaLabel
              ctaTo
            }
          }
          seoTitle
          seoDescription {
            seoDescription
          }
        }
      }
    `}
    render={(data: { page: IIndexPageData; contentfulLandingPageContent: IIndexPageData }) => (
      <Page {...props} data={data.contentfulLandingPageContent} />
    )}
  />
)

export default PageWithQuery
