import cx from 'classnames'
import React from 'react'

import * as styles from './divider.module.scss'

const Divider = (): JSX.Element => (
  <div className="grid">
    <div className={cx('col-desk-12', 'col-tab-12', 'col-mob-4')}>
      <hr className={styles.divider} />
    </div>
  </div>
)

Divider.defaultProps = {}

export default Divider
