import { navigate } from 'gatsby'
import isNil from 'lodash/isNil'
import { Dispatch, SetStateAction } from 'react'

import { IImage, IIndividualProductProps, ILink, ILinkWithClickHandler } from '@alteos/ui/types'
import {
  IBenefits,
  ICompleteCardProps,
} from '@alteos/ui/types/kits/lp/sections/CompleteCardsSection/interfaces'

import { IContactUsFormContent, IFormContent } from '../components/ContactModal/Form/interfaces'
import { IBasePageContent, IFaqContent, IHeroContent, IIndividualCTAContent, ISectionalContent, ISectionQuoteContent } from '../types/IBaseContentful'

interface IMapSeoElements {
  title: string
  description?: string | null
}

enum CtaColor {
  'primary' = 'primary',
  'secondary' = 'secondary',
  'tertiary' = 'tertiary',
  'outline' = 'outline',
  'ghost' = 'ghost',
}

interface IGetCtaParams {
  handleClick?: () => void
  label?: string
  to: string
  icon?: string
  color?: CtaColor
  targetBlank?: boolean
  ctaButton?: ILinkWithClickHandler | null
}

interface IOption {
  withLogoCaption?: boolean
  withTagline?: boolean
  withCtaButton?: boolean
  withCtaLink?: boolean
  withCtaButtons?: boolean
}

interface IMapFeaturesContent {
  title: string | null
  content?: string | null
  image: IImage | null
}

interface IMapFeaturesContentMappedItems extends IMapFeaturesContent {
  caption: string
  disabled: boolean
  url: string
  ctaButton?: {
    to: string
    label: string
    targetBlank: boolean
    icon: string
  }

  ctaButtons?: ILink[] | null
  // NOTE: these properties are here to resolve conflict with UI lib and these are typed here because in UI these interfaces are not exported
  coloredList?: string
  content: string
  withOrderNumber?: boolean
  withBackground?: boolean
  noPaddingBottom?: boolean
  twoColumns?: boolean
  type?: string
  title: string
  featureNumbered?: boolean
  titleClass?: string
  container?: boolean
  darkMode?: boolean
  backgroundImage?: IImage
  dataTest?: string
  id?: string
}

interface IMapFeaturesContentResult extends IMapFeaturesContent {
  items: IMapFeaturesContentMappedItems[]
  content: string
  logoCaption: string
  tagline?: string
  ctaButton?: {
    to: string
    label: string
  }
  ctaLink?: {
    to: string
    label: string
  }
  technicalDesignSystemConfig?: any
  ctaButtons: (
    | {
        handleClick: Dispatch<React.SetStateAction<boolean>> | null
        label: string
        color: CtaColor
        icon: string
        to: string
      }
    | {
        to: string
        label: string
        color: CtaColor
        icon: string
        handleClick?: undefined
      }
  )[]
}

interface IMapProductFeaturesContent {
  title: string | undefined
  allProductFeaturesList: string[]
  styles?: { [key: string]: string }
  ctaButton?: {
    to?: string
    label?: string
  }
  footerColumns?: number
  items: IIndividualProductProps[]
}

interface IMapFaqSectionItem {
  title: string
  content: string
}

interface IMapFaqSection {
  title: string
  items: IMapFaqSectionItem[]
  ctaLink: IGetCtaParams | null
}

interface IMapHeroContent {
  title: string
  subtitle: string
  image: IImage
  ctaButtons: (
    | {
        handleClick: {
          (value: SetStateAction<boolean>): void
          (value: SetStateAction<boolean>): void
        }
        label: string
        color: string
        icon: string
      }
    | {
        to: string
        label: string
        color: string
        icon: string
      }
  )[]
}

interface IArrayOfSlidesEntity {
  title: string
  label?: string
}

interface IMapQuoteContentResult {
  image: IImage
  slides?: IArrayOfSlidesEntity[]
  ctaButtons: (
    | {
        handleClick: Dispatch<React.SetStateAction<boolean>> | null
        label: string
        color: string
        icon: string
        to?: undefined
      }
    | {
        to: string
        label: string
        color: string
        icon: string
        handleClick?: undefined
      }
  )[]
}

interface IMapCompleteCardContent {
  items: Array<Partial<ICompleteCardProps>>
  title?: string
}

export const mapHeroContent = (
  content: IHeroContent,
  _: { withCtaButtons: boolean },
  changePopupState: {
    (value: SetStateAction<boolean>): void
    (value: SetStateAction<boolean>): void
  }
): IMapHeroContent => ({
  title: content.title.title,
  subtitle: content.subTitle ? content.subTitle.subTitle : '',
  image: content.image,
  ctaButtons: [
    {
      handleClick: changePopupState,
      label: 'mehr erfahren',
      color: 'primary',
      icon: 'phone',
    },
    {
      to: '/registrieren',
      label: 'jetzt anmelden',
      color: 'secondary',
      icon: 'arrow-right',
    },
  ],
})

export const mapFeaturesContent = (
  content: ISectionalContent,
  options: IOption = {},
  changePopupState: Dispatch<React.SetStateAction<boolean>> | null = null
): IMapFeaturesContentResult => {
  const result: IMapFeaturesContentResult = {} as IMapFeaturesContentResult

  // check if technicalDesignSystemConfig is present
  if (content?.technicalDesignSystemConfig) {
    result.technicalDesignSystemConfig = content.technicalDesignSystemConfig
  }
  if (content?.image) {
    result.image = content.image
  }

  if (content?.title?.title) {
    result.title = content.title.title
  }

  if (content?.content?.content) {
    result.content = content.content.content
  }

  if (Array.isArray(content?.components) && content.components.length > 1) {
    let items = []
    items = content.components.map((item) => {
      const mappedItem: Partial<IMapFeaturesContentMappedItems> = {}

      if (item.title?.title) {
        mappedItem.title = item.title.title
      }

      if (typeof item.content?.content === 'string') {
        mappedItem.content = item.content.content
      }

      if (item.image) {
        mappedItem.image = item.image
      }

      if (!isNil(item.cta)) {
        mappedItem.ctaButton = {
          to: item.cta.ctaTo,
          label: item.cta.ctaLabel,
          targetBlank: true,
          icon: item.cta.icon,
        }
      }

      return mappedItem
    })
    result.items = items
  }

  if (options.withLogoCaption) {
    result.logoCaption = content.content?.content
  }

  if (options.withTagline) {
    result.tagline = content?.subtitle
  }

  if (options.withCtaButton && content.cta) {
    const cta = {
      to: content.cta.ctaTo,
      label: content.cta.ctaLabel,
    }
    result.ctaButton = cta
  }

  if (options.withCtaLink && content.cta) {
    const cta = {
      to: content.cta.ctaTo,
      label: content.cta.ctaLabel,
    }
    result.ctaLink = cta
  }
  // TODO: This hardcoded value should be removed and replaced with a dynamic value from contentful
  if (options.withCtaButtons || content?.multipleButtons) {
    const ctaButtons = [
      {
        handleClick: changePopupState,
        label: 'mehr erfahren',
        color: CtaColor.primary,
        icon: 'phone',
      },
      {
        to: '/registrieren',
        label: 'jetzt anmelden',
        color: CtaColor.secondary,
        icon: 'arrow-right',
      },
    ] as IMapFeaturesContentResult['ctaButtons']
    result.ctaButtons = ctaButtons
  }

  return result
}

export const getCtaParams = (
  cta: IContentEntityOrMainRedirectCtaOrSubRedirectCta | null,
  handleClick?: Dispatch<React.SetStateAction<boolean>>
): IGetCtaParams | null => {
  if (cta === null) {
    return null
  }

  if (cta.ctaTo === '/kontakt') {
    return {
      label: cta.ctaLabel,
      handleClick: handleClick as IGetCtaParams['handleClick'],
    } as IGetCtaParams
  }

  if (cta.ctaTo === ':back') {
    return {
      handleClick: () => navigate(-1),
      label: cta.ctaLabel,
    } as unknown as IGetCtaParams
  }

  return {
    to: cta.ctaTo,
    label: cta.ctaLabel,
  }
}

export const mapSeoElements = (data: IBasePageContent): IMapSeoElements => ({
  title: data.seoTitle,
  description: data.seoDescription !== null ? data.seoDescription.seoDescription : null,
})

export const mapFaqSection = (data: IFaqContent): IMapFaqSection => ({
  title: data.title,
  items: data.content?.map((item) => {
    const mappedItem: IMapFaqSectionItem = {} as IMapFaqSectionItem
    if (item.question) {
      mappedItem.title = item.question.question
    }
    if (item.answer) {
      mappedItem.content = item.answer.answer
    }
    return mappedItem
  }),
  // TODO: use new function to get cta params
  ctaLink: getCtaParams(data.cta as IIndividualCTAContent),
})

export const mapProductFeaturesContent = (data: ISectionalContent): IMapProductFeaturesContent => {
  const components = data.components === null ? [] : data.components
  const btn =
    data?.cta?.ctaTo && data?.cta?.ctaLabel
      ? {
          ctaButton: {
            to: data?.cta?.ctaTo,
            label: data?.cta?.ctaLabel,
            targetBlank: data?.cta?.openNewTab,
          },
        }
      : {}
  return {
    title: data.title?.title,
    allProductFeaturesList: data?.technicalDesignSystemConfig?.allProductFeaturesList ?? [],
    styles: data?.technicalDesignSystemConfig?.styles ?? {},
    items: components?.map((item) => ({
      title: item.mainTitle,
      subtitle: item.subtitle,
      tagline: item.tagline,
      isBestseller: item.defaultSetting?.isBestseller,
      selectedBorder: item.defaultSetting?.selectedBorder,
      features: item.planFeatures?.features,
    })) as IMapProductFeaturesContent['items'],
    ...btn,
  }
}

export const mapCompleteCardContent = (
  data: IHoerschutzPageMainContentEntity
): IMapCompleteCardContent => {
  if (data.technicalDesignSystemConfig && data.technicalDesignSystemConfig.detailedSection) {
    const items = data.components.map((card) => ({
      title: card.mainTitle,
      content: card.content.content,
      image: null,
    }))
    return {
      items: [
        {
          title: data.title.title,
          image: data.image ? data.image : null,
          price: data.subtitle ? data.subtitle : null,
          subtitle: data.content ? data.content.content : null,
          benefits: { items: [...items] } as unknown as IBenefits[],
          divider: true,
          twoColumns: true,
        },
      ],
    }
  }
  return {
    title: data?.title?.title,
    items: data?.components.map((card) => ({
      image: card?.image,
      title: card?.mainTitle,
      content: card?.content?.content,
      price: card?.price,
      divider: true,
      subtitle: card?.subtitle,
      label:
        card?.parameters && card?.parameters?.label
          ? {
              content: card.parameters.label.content,
              type: card.parameters.label.type,
            }
          : null,
    })),
  }
}

export const mapQuoteContent = (
  data: ISectionQuoteContent,
  changePopupState: Dispatch<SetStateAction<boolean>>
): IMapQuoteContentResult => {
  const result: IMapQuoteContentResult = {} as IMapQuoteContentResult

  if (data.arrayOfSlides) {
    result.slides = data.arrayOfSlides.map((slide) => ({
      title: slide.title.title,
      label: slide.label ? slide.label.label : '',
    }))
  }
  if (data.backgroundImage) {
    result.image = data.backgroundImage
  }

  if (data.multipleButtons) {
    const ctaButtons = [
      {
        handleClick: changePopupState,
        label: 'mehr erfahren',
        color: 'primary',
        icon: 'phone',
      },
      {
        to: '/registrieren',
        label: 'jetzt anmelden',
        color: 'secondary',
        icon: 'arrow-right',
      },
    ]
    result.ctaButtons = ctaButtons
  }
  return result
}

export const mapContactPopupContent = (
  data: IContactUsFormContent
): {
  heroTitle: string
  formContent: {
    subject?: IFormContent
    name?: IFormContent
    email?: IFormContent
    phone?: IFormContent
    description?: IFormContent
    disclaimer?: IFormContent
  }
  submitCta: string
  successRedirect: string
  failureRedirect: string
} => ({
  heroTitle: data.heroTitle.heroTitle,
  formContent: {
    subject: data.formContent.find((field) => field.fieldId === 'popup_subject'),
    name: data.formContent.find((field) => field.fieldId === 'popup_name'),
    email: data.formContent.find((field) => field.fieldId === 'popup_email'),
    phone: data.formContent.find((field) => field.fieldId === 'popup_phone'),
    description: data.formContent.find((field) => field.fieldId === 'popup_description'),
    disclaimer: data.formContent.find((field) => field.fieldId === 'popup_disclaimer'),
  },
  submitCta: data.submitCtaLabel,
  successRedirect: data.submitSuccessRedirect,
  failureRedirect: data.submitFailureRedirect,
})
